import LeadForm from '../../components/leads/LeadForm'
import LeadList from '../../components/leads/LeadList'
import DeleteLeadForm from '../../components/leads/DeleteLeadForm'
import LeadDetail from '../../components/leads/LeadDetail'


const leadRoutes = [
    {path: '/leads', permission: ['leads.cus_view_lead'], component: <LeadList/>},
    {path: '/leads/:id', permission: ['leads.cus_view_lead'], component: <LeadDetail/>},
    {path: '/leads/add', permission: ['leads.cus_add_lead'], component: <LeadForm/>},
    {path: '/leads/edit/:id', permission: ['leads.cus_change_lead'], component: <LeadForm/>},
    {path: '/leads/delete/:id', permission: ['leads.cus_delete_lead'], component: <DeleteLeadForm/>}
]

export default leadRoutes