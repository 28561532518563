import api from './api';

class UtilsService {
    getRelatedField(module, model){
        return api.get('api/core/related-field/' + module + '/'+ model + '/')
    }

    getFieldChoices(module, model, field){
        return api.get('api/core/field-choices/' + module + '/'+ model + '/' + field + '/')
    }
}

export default new UtilsService();