import { useState } from 'react';

import Topbar from './components/Topbar';
import Menu from './components/Menu';


const Header = (props) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    /**
     * Open the menu when having mobile screen
     */
     const openMenu = () => {
        setIsMenuOpened(!isMenuOpened);
        if (document.body) {
            if (isMenuOpened){
                document.body.classList.remove('sidebar-enable');
            }else{
                document.body.classList.add('sidebar-enable');
            }
        }
    };

    return <>
        <Topbar openLeftMenuCallBack={openMenu}></Topbar>
        <Menu isMenuOpened={isMenuOpened}></Menu>
    </>
}

export default Header;