import { useSelector } from 'react-redux';
import { useRef, useState, useEffect, useMemo } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/es-mx';

import { hasPermissions } from '../../utils/permissions';
import { checkSuccessMessage } from '../../utils/messages';
import Pagination from '../layout/components/Pagination';
import Actions from '../layout/components/Actions';
import Layout from '../layout/Layout'
import CardMenu from '../layout/components/CardMenu'
import Modal from '../layout/components/Modal';
import LeadService from '../../services/lead.service';
import UtilsService from '../../services/utils.service';
import CustomerService from '../../services/customer.service';


const LeadList = () => {
    const permissions = useSelector(state => state.Auth.permissions)
    const modalRef = useRef()
    const location = useLocation()
    const currentPath = location.pathname + location.search;
    const [loadingData, setLoadingData] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [pagination, setPagination] = useState({previous: null, next: null})
    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (newValue) => {
        setSearchTerm(newValue);
    };

    const filters = {
        q: searchParams.get('q'),
        customer: searchParams.getAll('customer').filter(element => {return element !== ''}),
        status: searchParams.getAll('status').filter(element => {return element !== ''}),
        sales_channel: searchParams.getAll('sales_channel').filter(element => {return element !== ''}),
        seller: searchParams.getAll('seller').filter(element => {return element !== ''}),
        initial_date: searchParams.get('initial_date'),
        final_date: searchParams.get('final_date'),
        page: searchParams.get('page') || 1
    }

    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [customers, setCustomers] = useState([])
    const loadCustomers = async () => {
        CustomerService.getRelatedCustomers()
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label};
            });
            setCustomers(res);

            const urlCustomers = res.map((obj)=>{
                if(filters['customer'].includes(obj.value.toString())) return obj
            });
            setSelectedCustomers(urlCustomers);
        })
    }
    const customerOptions = useMemo(() => {
        const filtered = customers.filter((customer) =>
            customer.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [customers, searchTerm]);

    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState();
    const loadStatus = async (reload=false) => {
        UtilsService.getFieldChoices('leads', 'Lead', 'status')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt[0], label: opt[1]};
            });
            setStatus(res);

            const urlStatus = res.map((obj)=>{
                if(filters['status'].includes(obj.value.toString())) return obj
            });
            setSelectedStatus(urlStatus);
        })
    }
    const statusOptions = useMemo(() => {
        const filtered = status.filter((statu) =>
            statu.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [status, searchTerm]);

    const [salesChannels, setSalesChannels] = useState([]);
    const [selectedSalesChannels, setSelectedSalesChannels] = useState();
    const loadSalesChannels = async (reload=false) => {
        UtilsService.getFieldChoices('leads', 'Lead', 'sales_channel')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt[0], label: opt[1]};
            });
            setSalesChannels(res);

            const urlSalesChannels = res.map((obj)=>{
                if(filters['sales_channel'].includes(obj.value.toString())) return obj
            });
            setSelectedSalesChannels(urlSalesChannels);
        })
    }
    const salesChannelOptions = useMemo(() => {
        const filtered = salesChannels.filter((salesChannel) =>
            salesChannel.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [salesChannels, searchTerm]);

    const [selectedSellers, setSelectedSellers] = useState([])
    const [sellers, setSellers] = useState([])
    const loadSellers = async () => {
        UtilsService.getRelatedField('sellers', 'Seller')
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label};
            });
            setSellers(res);

            const urlSellers = res.map((obj)=>{
                if(filters['seller'].includes(obj.value.toString())) return obj
            });
            setSelectedSellers(urlSellers);
        })
    }
    const sellerOptions = useMemo(() => {
        const filtered = sellers.filter((seller) =>
            seller.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [sellers, searchTerm]);

    const [leads, setLeads] = useState([])
    const loadLeads = async () => {
        setLoadingData(true);
        LeadService.getLeads(filters)
        .then((response) => {
            setLeads(response.data.results);
            setPagination({
                previous: response.data.previous,
                next: response.data.next 
            })
            setLoadingData(false);
        })
    }

    moment.locale('es-mx')

    useEffect(() => {
        loadLeads();
        loadCustomers();
        loadStatus();
        loadSalesChannels();
        loadSellers();
        checkSuccessMessage();
    }, []);

    return <Layout title='Leads'>
        <CardMenu>
            <div className="mb-3 text-end">
                <button className='btn btn-light me-1' onClick={() => {modalRef.current.toggleModal()}}>Filtros</button>
                {hasPermissions(['leads.cus_add_lead'], permissions) && 
                    <Link 
                    to={'/leads/add' + '?prev_url=' + currentPath}
                    className='btn btn-primary'>
                        Nuevo lead
                    </Link>
                }
            </div>
            <div className='table-responsive table-grid'>
                <table className='table-centered table table-hover'>
                    <thead>
                        <tr>
                            <th># Lead</th>
                            <th>Cliente</th>
                            <th>Empresa</th>
                            <th>Estatus</th>
                            <th>Canal de venta</th>
                            <th>Solicitud del cliente</th>
                            <th>Fecha creación</th>
                            {
                                hasPermissions(['leads.cus_view_leads_from_other_sellers'], permissions) &&
                                <th>Vendedor</th>
                            }
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loadingData ?
                                leads.length ? 
                                    leads.map((obj, i) => {
                                        return <tr key={i}>
                                            <td>{obj.id}</td>
                                            <td>{obj.contact?.name}</td>
                                            <td>{obj.contact?.customer?.company}</td>
                                            <td>{obj.status_display}</td>
                                            <td>{obj.sales_channel_display}</td>
                                            <td>{obj.client_request}</td>
                                            <td>{moment(obj.created_at).format('LLLL')}</td>
                                            {
                                                hasPermissions(['leads.cus_view_leads_from_other_sellers'], permissions) &&
                                                <td>{obj.seller?.identifier} - {obj.seller?.user?.first_name} {obj.seller?.user?.last_name}</td>
                                            }
                                            <td>
                                                <Actions
                                                actions={[
                                                    {'label': 'Ver cotizaciones', 'link': 'quotes/?lead=' + obj.id, 'hasPerm': hasPermissions(['quotes.cus_view_quote'], permissions)},
                                                    {'label': 'Detalles', 'link': 'leads/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['leads.cus_view_lead'], permissions)},
                                                    {'label': 'Editar', 'link': 'leads/edit/' + obj.id  + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['leads.cus_change_lead'], permissions)},
                                                    {'label': 'Eliminar', 'link': 'leads/delete/' + obj.id  + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['leads.cus_delete_lead'], permissions)}
                                                ]}></Actions>
                                            </td>
                                        </tr>
                                })
                                :<tr><td colSpan={6}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                            :<tr><td colSpan={6}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                        }
                    </tbody>
                </table>
                <Pagination previous={pagination['previous']} next={pagination['next']}></Pagination>
            </div>
        </CardMenu>

        <Modal title='Filtros' ref={modalRef}>
            <form method='GET'>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Palabra clave</label>
                    <input type="text" className='form-control w-100' name='q' defaultValue={filters['q']} />
                </div>
                <div className='mb-3'>
                    <label htmlFor="customer" className="me-2">Cliente</label>
                    <Select
                        name='customer'
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        isMulti
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={customerOptions}
                        filterOption={() => true}
                        onInputChange={handleInputChange}
                        onChange={(option, action) => {setSelectedCustomers(option)}}
                        value={selectedCustomers}>
                    </Select>
                </div>
                <div className='mb-3'>
                    <label htmlFor="status" className="me-2">Estatus</label>
                    <Select
                        name='status'
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        isMulti
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={statusOptions}
                        filterOption={() => true}
                        onInputChange={handleInputChange}
                        onChange={(option, action) => {setSelectedStatus(option)}}
                        value={selectedStatus}>
                    </Select>
                </div>
                <div className='mb-3'>
                    <label htmlFor="sales_channel" className="me-2">Canal de venta</label>
                    <Select
                        name='sales_channel'
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        isMulti
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={salesChannelOptions}
                        filterOption={() => true}
                        onInputChange={handleInputChange}
                        onChange={(option, action) => {setSelectedSalesChannels(option)}}
                        value={selectedSalesChannels}>
                    </Select>
                </div>
                {
                    hasPermissions(['leads.cus_view_leads_from_other_sellers'], permissions) &&
                    <div className='mb-3'>
                        <label htmlFor="seller" className="me-2">Vendedor</label>
                        <Select
                            name='seller'
                            className="react-select"
                            classNamePrefix="react-select"
                            isClearable
                            isMulti
                            placeholder="Selecciona una opción"
                            noOptionsMessage={() => 'No se encontraron resultados'}
                            options={sellerOptions}
                            filterOption={() => true}
                            onInputChange={handleInputChange}
                            onChange={(option, action) => {setSelectedSellers(option)}}
                            value={selectedSellers}>
                        </Select>
                    </div>
                }
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Fecha inicio</label>
                    <input type="date" className='form-control w-100' name='initial_date' defaultValue={filters['initial_date']} />
                </div>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Fecha fin</label>
                    <input type="date" className='form-control w-100' name='final_date' defaultValue={filters['final_date']} />
                </div>
                <div className="mb-3 text-end">
                    <input type="submit" className='btn btn-primary' value='Buscar' />
                </div>
            </form>
        </Modal>

    </Layout>
}

export default LeadList