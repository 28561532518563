import { 
    LOGIN_SUCCESS,
    LOGOUT
} from '../actions/types';

const initialState = {
    isAuthenticated: false,
    name: null,
    email: null,
    permissions: null
}

export default function Auth(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case LOGIN_SUCCESS:
            localStorage.setItem('access', payload.access);
            localStorage.setItem('refresh', payload.refresh);
            return {
                ...state,
                isAuthenticated: true,
                name: payload.name,
                email: payload.email,
                permissions: payload.permissions,
                expires_at: payload.expires_at
            }
        case LOGOUT:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return initialState
        default:
            return state
    }
}