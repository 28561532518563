import RoleList from '../../components/roles/RoleList'
import RoleForm from '../../components/roles/RoleForm'
import RoleDetail from '../../components/roles/RoleDetail'
import DeleteRoleForm from '../../components/roles/DeleteRoleForm'


const roleRoutes = [
    {path: '/roles', permission: ['users.cus_view_role'], component: <RoleList/>},
    {path: '/roles/:id', permission: ['users.cus_view_role'], component: <RoleDetail/>},
    {path: '/roles/add', permission: ['users.cus_add_role'], component: <RoleForm/>},
    {path: '/roles/edit/:id', permission: ['users.cus_change_role'], component: <RoleForm/>},
    {path: '/roles/delete/:id', permission: ['users.cus_delete_role'], component: <DeleteRoleForm/>}
]

export default roleRoutes