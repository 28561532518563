import { 
    LOGIN_SUCCESS,
    LOGOUT,
} from '../actions/types';
import AuthService from '../../services/auth.service';
import { displayErrorMessages } from '../../utils/messages';


export const login = (email, password) => async dispatch => {

    AuthService.login(email, password)
    .then((response) => {
        if (response.status === 200) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data
            });
        }
    })
    .catch(error => displayErrorMessages(error.response.data))
    
}

export const logout = () => async dispatch => {
    dispatch({
        type: LOGOUT
    });
}