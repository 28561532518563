import { Link } from 'react-router-dom';


const Error = (props) => {
    return <div className="mt-5 mb-5">
        <div className="container">
            <div className="justify-content-center row">
                <div className="col-xl-10">
                    <div className="text-center">
                        <h1 className="text-error mt-4">{props.code}</h1>
                        <h4 className="text-uppercase text-danger mt-3">{props.title}</h4>
                        <p className="text-muted">{props.message}</p>
                        <Link to='/' className='btn btn-info mt-3'>
                            Regresar a inicio.
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Error;