import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

import Layout from '../layout/Layout'
import CardMenu from '../layout/components/CardMenu'
import QuoteService from '../../services/quote.service';
import { moneyFormat } from '../../utils/currencies';


const PriceLists = () => {
    const [loadingData, setLoadingData] = useState(false)
    const [priceList, setPriceList] = useState([])
    const [searchResult, setSearchResult] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const filteredPriceList = useMemo(() => {
        if (searchQuery === '') {
        return priceList;
        }
        return priceList.filter(item => item.product_number.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [priceList, searchQuery]);

    const loadPrices = async () => {
        setLoadingData(true);
        QuoteService.getPrices()
        .then((response) => {
            setPriceList(response.data);
            setLoadingData(false);
        })
    }

    const handleSearchInputChange = event => {
        const value = event.target.value;
        setSearchQuery(value);
    };

    const debouncedSearch = useMemo(() => debounce(setSearchResult, 300), []);

    useEffect(() => {
        loadPrices();
    }, []);

    useEffect(() => {
        debouncedSearch(filteredPriceList);
        return debouncedSearch.cancel;
    }, [debouncedSearch, filteredPriceList]);

    return <Layout title='Lista de precios'>
        <CardMenu>
            <div className="row mb-3">
                <div className='col-md-4'>
                    <input type="text" className='form-control' placeholder='Buscar' value={searchQuery} onChange={handleSearchInputChange}/>
                </div>
            </div>
            <div className='table-responsive table-grid'>
                <table className='table-centered table table-hover'>
                    <thead>
                        <tr>
                            <th>Modelo</th>
                            <th>Catálogo</th>
                            <th>Precio 3%</th>
                            <th>Precio 5%</th>
                            <th>Precio 10%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loadingData ?
                                filteredPriceList.length ? 
                                    filteredPriceList.map((obj, i) => {
                                        return <tr key={i}>
                                            <td>{obj.product_number}</td>
                                            <td>{obj.catalog}</td>
                                            <td>{moneyFormat(obj.price_3, 'MXN')[0]}</td>
                                            <td>{moneyFormat(obj.price_5, 'MXN')[0]}</td>
                                            <td>{moneyFormat(obj.price_10, 'MXN')[0]}</td>
                                        </tr>
                                })
                                :<tr><td colSpan={6}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                            :<tr><td colSpan={6}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </CardMenu>

    </Layout>
}

export default PriceLists