import api from './api';


class UserService {
    getUsers(params={}){
        return api.get('/api/users/', {params})
    }

    getUser(id){
        return api.get('/api/users/' + id + '/')
    }

    createUser(payload={}){
        return api.post('/api/users/', payload)
    }

    updateUser(id, payload={}){
        return api.put('/api/users/' + id + '/', payload)
    }

    updatePassword(id, payload={}){
        return api.put('/api/users/password/reset/' + id + '/', payload)
    }

    deleteUser(id){
        return api.delete('/api/users/' + id + '/')
    }
}

export default new UserService();