import api from './api'

class QuoteService {
    getQuotes(params={}){
        return api.get('/api/quotes/', {params});
    }

    getQuote(id){
        return api.get('/api/quotes/' + id + '/')
    }

    createQuote(payload={}){
        return api.post('/api/quotes/', payload)
    }

    updateQuote(id, payload={}){
        return api.put('/api/quotes/' + id + '/', payload)
    }

    deleteQuote(id){
        return api.delete('/api/quotes/' + id + '/')
    }

    getRelatedLeads(){
        return api.get('/api/quotes/related-leads/')
    }

    getRelatedItems(){
        return api.get('/api/quotes/related-items/')
    }

    downloadPDF(id){
        return api.get('/api/quotes/pdf/'+ id + '/', {responseType: 'blob'})
    }

    getPrices(){
        return api.get('/api/quotes/price-lists/')
    }
}

export default new QuoteService()