import Layout from './layout/Layout';

import welcomeImg from '../assets/images/welcome.gif';


const Home = (props) => {

    return <Layout title='¡Bienvenido, que gusto verte!'>
        <div className="row justify-content-sm-center text-center">
            <div className="col-12">
                <img className="img-fluid" width="800" height="auto" src={welcomeImg} alt="Bienvenido"/>
            </div>
        </div>
    </Layout>
}


export default Home