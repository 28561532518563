import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es-mx';

import Layout from '../layout/Layout';
import ItemService from '../../services/item.service';


const ItemDetail = () => {

    const [item, setItem] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const loadItem = async () => {
        ItemService.getItem(id)
        .then(response => {
            setItem(response.data)
        })
    }

    moment.locale('es-mx')

    useEffect(() => {
        loadItem();
    }, [])

    return <Layout>

        <div className='row justify-content-center'>
            <div className='col-md-4'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Detalles del artículo</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Alias:</span>
                                {item.alias}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Descripción:</span>
                                {item.description}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Imagen:</span>
                                <img src={`${item.image}?token=${localStorage.getItem('access')}`} alt={item.alias} width={200} height={200}/>
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Creado por:</span>
                                {item.created_by} el {moment(item.created_at).format('LLLL')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Última modificación por:</span>
                                {item.last_updated_by} el {moment(item.last_updated_at).format('LLLL')}
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div className='col-12'>
                <div className='d-flex justify-content-between mt-4 mb-2'>
                    <button className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/quotes/items')}>Regresar</button>
                </div>
            </div>

        </div>

    </Layout>
}

export default ItemDetail;