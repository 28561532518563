import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es-mx';

import Layout from '../layout/Layout';
import UserService from '../../services/user.service';


const UserDetail = () => {

    const [user, setUser] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const loadUser = async () => {
        UserService.getUser(id)
        .then(response => {
            setUser(response.data)
        })
    }

    moment.locale('es-mx')

    useEffect(() => {
        loadUser();
    }, [])

    return <Layout>

        <div className='row justify-content-center'>
            <div className='col-md-4'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Detalles de usuario</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Nombre:</span>
                                {user.first_name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Apellidos:</span>
                                {user.last_name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Correo electrónico:</span>
                                {user.email}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Departamento:</span>
                                {user.department?.name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Estatus:</span>
                                {user.is_active ? 'Activo': 'Inactivo'}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Creado por:</span>
                                {user.created_by} el {moment(user.created_at).format('LLLL')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Última modificación por:</span>
                                {user.last_updated_by} el {moment(user.last_updated_at).format('LLLL')}
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div className='col-md-4'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Roles de usuario</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            {
                                user.groups?.map((obj, i) => {
                                    return <li key={i} className='mb-2'>{obj.name}</li>
                                })
                            }
                        </ul>
                    </div>
                </div>

            </div>

            <div className='col-md-4'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Permisos específicos de usuario</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            {
                                user.user_permissions?.map((obj, i) => {
                                    return <li key={i} className='mb-2'>{obj.name}</li>
                                })
                            }
                        </ul>
                    </div>
                </div>

            </div>

            <div className='col-12'>
                <div className='d-flex justify-content-between mt-4 mb-2'>
                    <button className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/users')}>Regresar</button>
                </div>
            </div>

        </div>

    </Layout>
}

export default UserDetail;