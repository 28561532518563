import api from './api';


class DepartmentService{
    getDepartments(params={}){
        return api.get('/api/departments/', {params});
    }

    getDepartment(id){
        return api.get('/api/departments/' + id + '/')
    }

    createDepartment(payload={}){
        return api.post('/api/departments/', payload)
    }

    updateDepartment(id, payload={}){
        return api.put('/api/departments/' + id + '/', payload)
    }

    deleteDepartment(id){
        return api.delete('/api/departments/' + id + '/')
    }
}

export default new DepartmentService();