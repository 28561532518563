import QuoteForm from '../../components/quotes/QuoteForm'
import QuoteList from '../../components/quotes/QuoteList'
import DeleteQuoteForm from '../../components/quotes/DeleteQuoteForm'
import QuoteDetail from '../../components/quotes/QuoteDetail'
import PriceLists from '../../components/quotes/PriceLists'


const quoteRoutes = [
    {path: '/quotes', permission: ['quotes.cus_view_quote'], component: <QuoteList/>},
    {path: '/quotes/:id', permission: ['quotes.cus_view_quote'], component: <QuoteDetail/>},
    {path: '/quotes/add', permission: ['quotes.cus_add_quote'], component: <QuoteForm/>},
    {path: '/quotes/edit/:id', permission: ['quotes.cus_change_quote'], component: <QuoteForm/>},
    {path: '/quotes/delete/:id', permission: ['quotes.cus_delete_quote'], component: <DeleteQuoteForm/>},
    {path: '/quotes/prices', permission: ['quotes.cus_view_quote'], component: <PriceLists/>}
]

export default quoteRoutes