import UserList from '../../components/users/UserList';
import UserForm from '../../components/users/UserForm';
import PasswordForm from '../../components/users/PasswordForm';
import DeleteUserForm from '../../components/users/DeleteUserForm';
import UserDetail from '../../components/users/UserDetail';


const userRoutes = [
    {path: '/users', permission: ['users.cus_view_user'], component: <UserList/>},
    {path: '/users/:id', permission: ['users.cus_view_user'], component: <UserDetail/>},
    {path: '/users/add', permission: ['users.cus_add_user'], component: <UserForm/>},
    {path: '/users/edit/:id', permission: ['users.cus_change_user'], component: <UserForm/>},
    {path: '/users/edit/password/:id', permission: ['users.cus_change_user'], component: <PasswordForm/>},
    {path: '/users/delete/:id', permission: ['users.cus_delete_user'], component: <DeleteUserForm/>}
]

export default userRoutes