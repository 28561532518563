import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import persistStore from 'redux-persist/es/persistStore';

import store from './redux/store';
import Paths from './routes/Routes';
import setupInterceptor from './services/interceptors';

import './assets/scss/Saas.scss';


const persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Paths></Paths>
      </PersistGate>
    </Provider>
  );
}

setupInterceptor();

export default App;