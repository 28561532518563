import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';

import Auth from './reducers/auth';


const persistConfig = {
    key: 'root',
    storage
}

const reducers = combineReducers({
    Auth
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    preloadedState: {}
})

export default store