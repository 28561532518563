import api from './api'

class ItemService {
    getItems(params={}){
        return api.get('/api/quotes/items/', {params});
    }

    getItem(id){
        return api.get('/api/quotes/items/' + id + '/')
    }

    createItem(payload={}){
        return api.post('/api/quotes/items/', payload, {headers: {'Content-Type': 'multipart/form-data'}})
    }

    updateItem(id, payload={}){
        return api.put('/api/quotes/items/' + id + '/', payload, {headers: {'Content-Type': 'multipart/form-data'}})
    }

    deleteItem(id){
        return api.delete('/api/quotes/items/' + id + '/')
    }

    getItemInfo(id){
        return api.get('/api/quotes/item-info/' + id + '/');
    }
}

export default new ItemService()