import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es-mx';

import Layout from '../layout/Layout';
import LeadService from '../../services/lead.service';
import { moneyFormat } from '../../utils/currencies';

const LeadDetail = () => {
    const [lead, setLead] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const loadLead = async () => {
        LeadService.getLead(id)
        .then(response => {
            if(response.data.budget) response.data.budget = moneyFormat(response.data.budget, 'MXN')[0]
            setLead(response.data)
        })
    }

    moment.locale('es-mx')

    useEffect(() => {
        loadLead();
    }, [])

    return <Layout>

        <div className='row justify-content-center'>
            <div className='col-md-4'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Detalles del lead</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Cliente:</span>
                                {lead.contact?.name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Empresa:</span>
                                {lead.contact?.customer?.company}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Canal de venta:</span>
                                {lead.sales_channel_display}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Estatus:</span>
                                {lead.status_display}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Solicitud del cliente:</span>
                                {lead.client_request}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Presupuesto:</span>
                                {lead.budget}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Fecha solicitada:</span>
                                {lead.requested_date}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Vendedor:</span>
                                {lead.seller?.identifier} - {lead.seller?.user?.first_name} {lead.seller?.user?.last_name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Observaciones:</span>
                                {lead.observations}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Creado por:</span>
                                {lead.created_by} el {moment(lead.created_at).format('LLLL')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Última modificación por:</span>
                                {lead.last_updated_by} el {moment(lead.last_updated_at).format('LLLL')}
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div className='col-12'>
                <div className='d-flex justify-content-between mt-4 mb-2'>
                    <button className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/leads')}>Regresar</button>
                </div>
            </div>

        </div>

    </Layout>
}

export default LeadDetail;