import api from './api'


class RoleService{
    getRoles(params={}){
        return api.get('/api/users/roles/', {params})
    }

    getRole(id){
        return api.get('/api/users/roles/' + id + '/')
    }

    createRole(payload={}){
        return api.post('/api/users/roles/', {group: payload})
    }

    updateRole(id, payload={}){
        return api.put('/api/users/roles/' + id + '/', {group: payload})
    }

    deleteRole(id){
        return api.delete('/api/users/roles/' + id + '/')
    }
}

export default new RoleService()