import api from './api'


class SellerService {
    getSellers(params={}){
        return api.get('/api/sellers/', {params});
    }

    getSeller(id){
        return api.get('/api/sellers/' + id + '/')
    }

    createSeller(payload={}){
        return api.post('/api/sellers/', payload)
    }

    updateSeller(id, payload={}){
        return api.put('/api/sellers/' + id + '/', payload)
    }

    deleteSeller(id){
        return api.delete('/api/sellers/' + id + '/')
    }
}

export default new SellerService()