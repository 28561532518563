import { useState, forwardRef, useImperativeHandle } from 'react';
import { Modal as ReactModal } from 'react-bootstrap';


const Modal = forwardRef((props, ref) => {
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
    };

    useImperativeHandle(ref, () => ({
        toggleModal(){
            toggle();
        }
    }));

    return <ReactModal show={modal} onHide={toggle}>
            <ReactModal.Header onHide={toggle} closeButton>
                <h4 className="modal-title">{props.title}</h4>
            </ReactModal.Header>
            <ReactModal.Body>
                {props.children}
            </ReactModal.Body>
        </ReactModal>;
});

export default Modal;