import { toast } from 'react-toastify'


export const showMessage = (type, message) => {
    switch(type){
        case 'success':
            toast.success(message);
        break;
        case 'info':
            toast.info(message);
        break;
        case 'error':
            toast.error(message);
        break;
        case 'warning':
            toast.warning(message);
        break;
        default:
            toast(message);
        break;
    }
}

export const setSuccessMessage = message => {
    sessionStorage.setItem('successMessage', message)
}

export const checkSuccessMessage = () => {
    if(sessionStorage.getItem('successMessage')){
        toast.success(sessionStorage.getItem('successMessage').toString())
        sessionStorage.removeItem('successMessage');
    }
}

export const displayErrorMessages = (errors, field_name='') => {
    for(let key in errors){
        if(typeof(errors[key]) === 'object'){
            return displayErrorMessages(errors[key], key)
        }
        showMessage('error', field_name ? `${field_name} - ${errors[key].toString()}` : errors[key].toString())
    }
}