import SellerList from '../../components/sellers/SellerList'
import SellerForm from '../../components/sellers/SellerForm'
import SellerDetail from '../../components/sellers/SellerDetail'
import DeleteSellerForm from '../../components/sellers/DeleteSellerForm'


const sellerRoutes = [
    {path: '/sellers', permission: ['sellers.cus_view_seller'], component: <SellerList/>},
    {path: '/sellers/:id', permission: ['sellers.cus_view_seller'], component: <SellerDetail/>},
    {path: '/sellers/add', permission: ['sellers.cus_add_seller'], component: <SellerForm/>},
    {path: '/sellers/edit/:id', permission: ['sellers.cus_change_seller'], component: <SellerForm/>},
    {path: '/sellers/delete/:id', permission: ['sellers.cus_delete_seller'], component: <DeleteSellerForm/>}
]

export default sellerRoutes