import ItemForm from '../../components/items/ItemForm'
import ItemList from '../../components/items/ItemList'
import DeleteItemForm from '../../components/items/DeleteItemForm'
import ItemDetail from '../../components/items/ItemDetail'


const itemRoutes = [
    {path: '/quotes/items', permission: ['quotes.cus_view_quote'], component: <ItemList/>},
    {path: '/quotes/items/:id', permission: ['quotes.cus_view_quote'], component: <ItemDetail/>},
    {path: '/quotes/items/add', permission: ['quotes.cus_add_quote'], component: <ItemForm/>},
    {path: '/quotes/items/edit/:id', permission: ['quotes.cus_change_quote'], component: <ItemForm/>},
    {path: '/quotes/items/delete/:id', permission: ['quotes.cus_delete_quote'], component: <DeleteItemForm/>}
]

export default itemRoutes