import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Layout from '../../layout/Layout';
import CardMenu from '../../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages} from '../../../utils/messages';
import CustomerService from '../../../services/customer.service';


const CustomerContactForm = () => {
    const navigate = useNavigate();
    const { customerId, id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();


    let formFields = {
        customer: yup.string(),
        name: yup.string().required('Este campo es requerido'),
        phone: yup.string().required('Este campo es requerido'),
        email: yup.string().required('Este campo es requerido')
    }
    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            customer: customerId
        }
    });


    const onSubmitHandler = (data) => {
        setSaving(true)
        if(!id){
            CustomerService.createContact(data)
            .then(response => {
                setSuccessMessage('Contacto creado exitosamente')
                navigate('/customers/' + customerId + '/contacts')             
            })
            .catch(error => displayErrorMessages(error.response.data))
        }else{
            CustomerService.updateContact(id, data)
            .then(response => {
                setSuccessMessage('Cliente actualizado correctamente')
                navigate('/customers/' + customerId + '/contacts')
            })
            .catch(error => displayErrorMessages(error.response.data))
        }
        setSaving(false)
    };

    const loadContact = async () => {
        CustomerService.getContact(id)
        .then(response => {
            setValue('name', response.data.name)
            setValue('phone', response.data.phone)
            setValue('email', response.data.email)
        })
    }

    useEffect(() => {
        if(id){
            loadContact();
        }
    }, []);


    return <Layout title={!id ? 'Nuevo contacto': 'Editar contacto'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['name'].exclusiveTests.required && <span className="text-danger">*</span>} Nombre</label>
                    <input type="text" className='form-control' {...register('name')} />
                    {errors.name ? <div className='text-danger'>{errors.name?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['phone'].exclusiveTests.required && <span className="text-danger">*</span>} Teléfono</label>
                    <input type="tel" className='form-control' {...register('phone')} />
                    {errors.phone ? <div className='text-danger'>{errors.phone?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['email'].exclusiveTests.required && <span className="text-danger">*</span>} Correo electrónico</label>
                    <input type="email" className='form-control' {...register('email')} />
                    {errors.email ? <div className='text-danger'>{errors.email?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/customers/' + customerId + '/contacts')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default CustomerContactForm