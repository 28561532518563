import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/es-mx';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages, showMessage} from '../../utils/messages';
import { hasPermissions } from '../../utils/permissions';
import QuoteService from '../../services/quote.service';
import ItemService from '../../services/item.service';
import { moneyFormat } from '../../utils/currencies';


const QuoteForm = () => {
    const userPermissions = useSelector(state => state.Auth.permissions);
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (newValue) => {
        setSearchTerm(newValue);
    };

    const [leads, setLeads] = useState([]);
    const [selectedLead, setSelectedLead] = useState();
    const loadLeads = async (reload=false) => {
        QuoteService.getRelatedLeads()
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setLeads(res);
            if(reload) showMessage('success', 'Leads actualizados correctamente')
        })
    }
    const leadOptions = useMemo(() => {
        const filtered = leads.filter((lead) =>
            lead.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [leads, searchTerm]);

    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState({0: null});
    const [itemInfo, setItemInfo] = useState({0: null});
    const loadItems = async (reload=false) => {
        QuoteService.getRelatedItems()
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setItems(res);
            if(reload) showMessage('success', 'Artículos actualizados correctamente')
        })
    }
    const itemOptions = useMemo(() => {
        const filtered = items.filter((item) =>
            item.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [items, searchTerm]);

    const loadItemInfo = async (itemId, index) => {
        ItemService.getItemInfo(itemId)
        .then(response => {
            setItemInfo((prev) => {return {...prev, [index]: response.data}})
        })
    }

    let formFields = {
        lead: yup.string().required('Este campo es requerido'),
        payment_conditions: yup.string().required('Este campo es requerido'),
        validity: yup.date().required('Este campo es requerido').nullable().transform((curr, orig) => orig === '' ? null : curr),
        quote_items: yup.array().of(
            yup.object().shape({
                quote_item_id: yup.number().nullable(),
                item: yup.string().nullable().required('Este campo es requerido'),
                quantity: yup.number()
                    .positive('Este campo debe ser un número positivo')
                    .transform(value => (isNaN(value) ? undefined : value))
                    .required('Este campo es requerido'),
                unit_price: yup.number()
                    .positive('Este campo debe ser un número positivo')
                    .transform(value => (isNaN(value) ? undefined : value))
                    .required('Este campo es requerido'),
                delivery_time: yup.string().nullable().required('Este campo es requerido'),
                notes: yup.string().nullable()
            })
        ).min(1, 'Debes agregar al menos 1 artículo a la cotización')
    }

    const quote_items_default = {quote_item_id: null, item: null, quantity: null, unit_price: null, delivery_time: null}

    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            quote_items: [quote_items_default]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'quote_items'
    });

    const handleRemoveField = index => {
        let currentItems = selectedItem
        delete currentItems[index]

        let newItems = {}
        let i = 0;
        for (let key of Object.keys(currentItems)) {
            newItems[i] = currentItems[key]
            i++
        }
        setSelectedItem((prev) => { return {...newItems}})


        let currentItemInfo = itemInfo
        delete currentItemInfo[index]

        let newItemInfo = {}
        let j = 0;
        for (let key of Object.keys(currentItemInfo)) {
            newItemInfo[j] = currentItemInfo[key]
            j++
        }
        setItemInfo((prev) => { return {...newItemInfo}})

        remove(index)
    }

    const onSubmitHandler = (data) => {
        if(data.validity) data.validity = moment(data.validity).format('YYYY-MM-DD')
        setSaving(true)
        if(!id){
            QuoteService.createQuote(data)
            .then(response => {
                setSuccessMessage('Cotización creada exitosamente')
                navigate('/quotes')             
            })
            .catch(error => displayErrorMessages(error.response.data))
        }else{
            QuoteService.updateQuote(id, data)
            .then(response => {
                setSuccessMessage('Cotización actualizada correctamente')
                navigate('/quotes')
            })
            .catch(error => displayErrorMessages(error.response.data))
        }
        setSaving(false)
    };

    const loadQuote = async () => {
        QuoteService.getQuote(id)
        .then(response => {
            setValue('payment_conditions', response.data.payment_conditions)
            setValue('validity', response.data.validity)
            setValue('lead', response.data.lead.id)
            setSelectedLead({value: response.data.lead.id, label: `${response.data.lead.id} - ${response.data.lead?.contact?.name} - ${response.data.lead?.contact?.customer?.company} - ${moment(response.data.lead.created_at).format('YYYY/MM/DD')}`})
            
            let loaded_quote_items = []

            for(let i = 0; i < response.data.quote_items.length; i++){
                loaded_quote_items.push({
                    id: response.data.quote_items[i].id,
                    quote_item_id: response.data.quote_items[i].id,
                    item: response.data.quote_items[i].item.id,
                    quantity: response.data.quote_items[i].quantity,
                    unit_price: response.data.quote_items[i].unit_price,
                    delivery_time: response.data.quote_items[i].delivery_time,
                    notes: response.data.quote_items[i].notes
                })
                setSelectedItem((prev) => { return {...prev, [i]: {value: response.data.quote_items[i].item.id, label: response.data.quote_items[i].item.alias}}})
                loadItemInfo(response.data.quote_items[i].item.id, i)
            }

            setValue('quote_items', loaded_quote_items)

        })
    }

    useEffect(() => {
        loadLeads();
        loadItems();
        if(id) loadQuote();
    }, []);

    return <Layout title={!id ? 'Nueva cotización': 'Editar cotización'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="mb-3 border-bottom"><h4 className="font-weight-normal">Datos de la cotización</h4></div>
                <div className='mb-3 col-md-6'>
                    <div className='d-flex'>
                        <label className='form-label ms-1'>{schema.fields['lead'].exclusiveTests.required && <span className="text-danger">*</span>} Lead</label>
                        {hasPermissions(['leads.cus_add_lead'], userPermissions) &&
                        <div className='ms-1 mt-n1'>
                            (
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/leads/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadLeads(true)}}><i className='dripicons-retweet'></i></button>
                            )
                        </div>
                        }
                    </div>
                    
                    <Controller
                    name="lead"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={leadOptions}
                    filterOption={() => true}
                    onInputChange={handleInputChange}
                    onChange={(option, action) => {setValue('lead', option ? option.value : ''); setSelectedLead(option)}}
                    value={selectedLead}>
                    </Select>
        
                    {errors.lead ? <div className='text-danger'>{errors.lead?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['validity'].exclusiveTests.required && <span className="text-danger">*</span>} Vigencia</label>
                    <input type="date" className='form-control' {...register('validity')} />
                    {errors.validity ? <div className='text-danger'>{errors.validity?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['payment_conditions'].exclusiveTests.required && <span className="text-danger">*</span>} Condiciones de pago</label>
                    <input type="text" className='form-control' {...register('payment_conditions')} />
                    {errors.payment_conditions ? <div className='text-danger'>{errors.payment_conditions?.message}</div> : ''}
                </div>
                <div className="my-3 border-bottom"><h4 className="font-weight-normal">Artículos</h4></div>
                {
                    fields.map((field, index) => {
                        return <div key={field.id} className='row'>
                            <input type="hidden" {...register(`quote_items.${index}.quote_item_id`)}/>
                            <div className='mb-3 col-md-3'>
                                <div className='d-flex'>
                                    <label className='form-label ms-1'>{schema.fields['quote_items'].innerType.fields['item'].exclusiveTests.required && <span className="text-danger">*</span>} Artículo</label>
                                    {hasPermissions(['quotes.cus_add_quote'], userPermissions) &&
                                    <div className='ms-1 mt-n1'>
                                        (
                                        <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/quotes/items/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                                        <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadItems(true)}}><i className='dripicons-retweet'></i></button>
                                        )
                                    </div>
                                    }
                                </div>
                                
                                <Controller
                                name={`quote_items.${index}.item`}
                                control={control}
                                render={({ field }) => (
                                    <input type="hidden" {...field} value={field.value} />
                                )}
                                >
                                </Controller>
                                
                                <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                isClearable
                                placeholder="Selecciona una opción"
                                noOptionsMessage={() => 'No se encontraron resultados'}
                                options={itemOptions}
                                filterOption={() => true}
                                onInputChange={handleInputChange}
                                onChange={(option, action) => {setValue(`quote_items.${index}.item`, option ? option.value : ''); 
                                    setSelectedItem((prev) => {return {...prev, [index]: option}});
                                    loadItemInfo(option.value, index);
                                }}
                                value={selectedItem[index]}>
                                </Select>
                    
                                {errors.quote_items?.[index]?.item ? <div className='text-danger'>{errors.quote_items?.[index]?.item.message}</div> : ''}
                            </div>
                            <div className='mb-3 col-md-3'>
                                <label className='form-label'>{schema.fields['quote_items'].innerType.fields['quantity'].exclusiveTests.required && <span className="text-danger">*</span>} Cantidad</label>
                                <input type="number" className='form-control' {...register(`quote_items.${index}.quantity`)} />
                                {
                                    itemInfo[index]?.available ?
                                    <span className='text-success'>Stock disponible: {itemInfo[index]?.stock}</span>
                                    :
                                    <span className='text-danger'>Información no disponible</span>
                                }
                                {errors.quote_items?.[index]?.quantity ? <div className='text-danger'>{errors.quote_items?.[index]?.quantity.message}</div> : ''}
                            </div>
                            <div className='mb-3 col-md-3'>
                                <label className='form-label'>{schema.fields['quote_items'].innerType.fields['unit_price'].exclusiveTests.required && <span className="text-danger">*</span>} Precio unitario (Sin IVA)</label>
                                <input type="number" step='0.01' className='form-control' {...register(`quote_items.${index}.unit_price`)} />
                                {itemInfo[index]?.available && itemInfo[index]?.type === 'ERP' &&
                                <div className='table-responsive'>
                                    <table className='table table-sm'>
                                        <thead>
                                            <tr>
                                                <th>3%</th>
                                                <th>5%</th>
                                                <th>10%</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{moneyFormat(itemInfo[index]?.price_3, 'MXN')[0]}</td>
                                                <td>{moneyFormat(itemInfo[index]?.price_5, 'MXN')[0]}</td>
                                                <td>{moneyFormat(itemInfo[index]?.price_10, 'MXN')[0]}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                }

                                {itemInfo[index]?.available && itemInfo[index]?.type === 'API' &&
                                <div className='table-responsive'>
                                    <table className='table table-sm'>
                                        <thead>
                                            <tr>
                                                <th>Cantidad</th>
                                                <th>Precio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                itemInfo[index]?.prices.length &&
                                                itemInfo[index]?.prices.map((obj, i) => {
                                                    return <tr key={i}>
                                                        <td>{obj.min_piezas} - {obj.max_piezas || 'En adelante'}</td>
                                                        <td><td>{moneyFormat(obj.precio, 'MXN')[0]}</td></td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                }

                                {!itemInfo[index]?.available &&
                                <span className='text-danger'>Información no disponible</span>
                                }
                                {errors.quote_items?.[index]?.unit_price ? <div className='text-danger'>{errors.quote_items?.[index]?.unit_price.message}</div> : ''}
                            </div>
                            <div className='mb-3 col-md-3'>
                                <label className='form-label'>{schema.fields['quote_items'].innerType.fields['delivery_time'].exclusiveTests.required && <span className="text-danger">*</span>} Días de entrega</label>
                                <input type="text" className='form-control' {...register(`quote_items.${index}.delivery_time`)} />
                                {errors.quote_items?.[index]?.delivery_time ? <div className='text-danger'>{errors.quote_items?.[index]?.delivery_time.message}</div> : ''}
                            </div>
                            <div className='mb-3 col-md-3'>
                                <label className='form-label'>{schema.fields['quote_items'].innerType.fields['notes'].exclusiveTests.required && <span className="text-danger">*</span>} Notas</label>
                                <input type="text" className='form-control' {...register(`quote_items.${index}.notes`)} />
                                {errors.quote_items?.[index]?.notes ? <div className='text-danger'>{errors.quote_items?.[index]?.notes.message}</div> : ''}
                            </div>
                            <div className='mb-3 col-12'>
                                <button type="button" className='btn btn-outline-danger' onClick={() => handleRemoveField(index)}>
                                    Eliminar artículo
                                </button>
                            </div>
                        </div>
                    })
                }

                <div className='mb-3 col-md-3'>
                    <button type="button" className='btn btn-outline-primary' onClick={() => {append(quote_items_default)}}>
                        Agregar artículo
                    </button>
                    {errors.quote_items ? <div className='text-danger'>{errors.quote_items?.message}</div> : ''}
                </div>
                
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/quotes')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default QuoteForm