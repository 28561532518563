import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es-mx';

import Layout from '../layout/Layout';
import CustomerService from '../../services/customer.service';

const CustomerDetail = () => {
    const [customer, setCustomer] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const loadCustomer = async () => {
        CustomerService.getCustomer(id)
        .then(response => {
            setCustomer(response.data)
        })
    }

    moment.locale('es-mx')

    useEffect(() => {
        loadCustomer();
    }, [])

    return <Layout>

        <div className='row justify-content-center'>
            <div className='col-md-4'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Detalles del cliente</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Empresa:</span>
                                {customer.company}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Vendedor:</span>
                                {customer.seller?.identifier} - {customer.seller?.user?.first_name} {customer.seller?.user?.last_name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Creado por:</span>
                                {customer.created_by} el {moment(customer.created_at).format('LLLL')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Última modificación por:</span>
                                {customer.last_updated_by} el {moment(customer.last_updated_at).format('LLLL')}
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div className='col-12'>
                <div className='d-flex justify-content-between mt-4 mb-2'>
                    <button className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/customers')}>Regresar</button>
                </div>
            </div>

        </div>

    </Layout>
}

export default CustomerDetail;