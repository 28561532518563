import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages} from '../../utils/messages';
import ItemService from '../../services/item.service';


const ItemForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    let formFields = {
        alias: yup.string().required('Este campo es requerido'),
        description: yup.string().required('Este campo es requerido')
            .max(2000, 'EL máximo de caracteres es 2000'),
        seller: yup.string()
    }

    if(!id){
        formFields['image'] = yup.mixed()
            .test('required', 'Este campo es requerido', value => value.length)
            .test('fileSize', 'El tamaño maximo permitido es 64MB', value => (value[0]?.size / 1024) <= (1024 * 64))
    }else{
        formFields['image'] = yup.mixed()
            .test('fileSize', 'El tamaño maximo permitido es 64MB', value => {
                if(!value.length) return true
                return (value[0]?.size / 1024) <= (1024 * 64)
            })
    }

    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = (data) => {
        data.image = data.image[0]
        setSaving(true)
        if(!id){
            ItemService.createItem(data)
            .then(response => {
                setSuccessMessage('Artículo creado exitosamente')
                navigate('/quotes/items')             
            })
            .catch(error => displayErrorMessages(error.response.data))
        }else{
            ItemService.updateItem(id, data)
            .then(response => {
                setSuccessMessage('Artículo actualizado correctamente')
                navigate('/quotes/items')
            })
            .catch(error => displayErrorMessages(error.response.data))
        }
        setSaving(false)
    };

    const [itemImg, setItemImg] = useState('')
    const loadItem = async () => {
        ItemService.getItem(id)
        .then(response => {
            setValue('alias', response.data.alias)
            setValue('description', response.data.description)
            setItemImg(response.data.image)
        })
    }

    useEffect(() => {
        if(id) loadItem();
    }, []);


    return <Layout title={!id ? 'Nuevo artículo': 'Editar artículo'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['alias'].exclusiveTests.required && <span className="text-danger">*</span>} Alias</label>
                    <input type="text" className='form-control' {...register('alias')} />
                    {errors.alias ? <div className='text-danger'>{errors.alias?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['image'].tests[0].OPTIONS.name == 'required' && <span className="text-danger">*</span>} Imagen</label>
                    {id && <img src={`${itemImg}?token=${localStorage.getItem('access')}`} alt='Artículo' width={70} height={70}/>}
                    <input type="file" className='form-control' {...register('image')} />
                    {errors.image ? <div className='text-danger'>{errors.image?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['description'].exclusiveTests.required && <span className="text-danger">*</span>} Descripción</label>
                    <textarea cols="50" rows="8" className='form-control' {...register('description')}></textarea>
                    {errors.description ? <div className='text-danger'>{errors.description?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/quotes/items')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default ItemForm