import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { setSuccessMessage, displayErrorMessages } from '../../utils/messages';
import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import RoleService from '../../services/role.service';


const DeleteRoleForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [role, setRole] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const loadRole = async () => {
        RoleService.getRole(id)
        .then(response => {
            setRole(response.data)
        })
    }

    const handleSubmit = e => {
        e.preventDefault();

        RoleService.deleteRole(id)
        .then(response => {
            setSuccessMessage('Rol eliminado exitosamente')
            navigate('/roles')      
        })
        .catch(error => displayErrorMessages(error.response.data))
    }

    useEffect(() => {
        loadRole();
    }, [])

    return <Layout title='Eliminar rol'>
        <CardMenu>
            <div role="alert" className="fade bg-white alert alert-danger show">
                <h4 className="alert-heading">¿Deseas eliminar el rol {role.group?.name}?</h4>
                <p>Esta acción no se puede deshacer</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/roles')}>Cancelar</button>
                        <button type='submit' className='btn btn-danger'>Eliminar</button>
                    </div>
                </div>
            </form>            
        </CardMenu>
    </Layout>
}

export default DeleteRoleForm