import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages, showMessage} from '../../utils/messages';
import { hasPermissions } from '../../utils/permissions';
import SellerService from '../../services/seller.service';
import UtilsService from '../../services/utils.service';


const SellerForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const userPermissions = useSelector(state => state.Auth.permissions);
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (newValue) => {
        setSearchTerm(newValue);
    };

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const loadUsers = async (reload=false) => {
        UtilsService.getRelatedField('users', 'User')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setUsers(res);
            if(reload) showMessage('success', 'Usuarios actualizados correctamente')
        })
    }
    const userOptions = useMemo(() => {
        const filtered = users.filter((user) =>
            user.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [users, searchTerm]);

    let formFields = {
        identifier: yup.string().required('Este campo es requerido'),
        office_phone: yup.string().max(20, 'El máximo de caracteres es 20').nullable(),
        personal_phone: yup.string().max(20, 'El máximo de caracteres es 20').nullable(),
        user: yup.string().required('Este campo es requerido')
    }
    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = (data) => {
        setSaving(true)
        if(!id){
            SellerService.createSeller(data)
            .then(response => {
                setSuccessMessage('Vendedor creado exitosamente')
                navigate('/sellers')             
            })
            .catch(error => displayErrorMessages(error.response.data))
        }else{
            SellerService.updateSeller(id, data)
            .then(response => {
                setSuccessMessage('Vendedor actualizado correctamente')
                navigate('/sellers')
            })
            .catch(error => displayErrorMessages(error.response.data))
        }
        setSaving(false)
    };

    const loadSeller = async () => {
        SellerService.getSeller(id)
        .then(response => {
            setValue('identifier', response.data.identifier)
            setValue('office_phone', response.data.office_phone)
            setValue('personal_phone', response.data.personal_phone)
            setValue('user', response.data.user.id)
            setSelectedUser({value: response.data.user.id, label: `${response.data.user.first_name} ${response.data.user.last_name}`})
        })
    }

    useEffect(() => {
        loadUsers();
        if(id) loadSeller();
    }, []);


    return <Layout title={!id ? 'Nuevo vendedor': 'Editar vendedor'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['identifier'].exclusiveTests.required && <span className="text-danger">*</span>} No. de vendedor</label>
                    <input type="text" className='form-control' {...register('identifier')} />
                    {errors.identifier ? <div className='text-danger'>{errors.identifier?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <div className='d-flex'>
                        <label className='form-label ms-1'>{schema.fields['user'].exclusiveTests.required && <span className="text-danger">*</span>} Usuario</label>
                        {hasPermissions(['users.cus_add_user'], userPermissions) &&
                        <div className='ms-1 mt-n1'>
                            (
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/users/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadUsers(true)}}><i className='dripicons-retweet'></i></button>
                            )
                        </div>
                        }
                    </div>
                    
                    <Controller
                    name="user"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={userOptions}
                    filterOption={() => true}
                    onInputChange={handleInputChange}
                    onChange={(option, action) => {setValue('user', option ? option.value : ''); setSelectedUser(option)}}
                    value={selectedUser}>
                    </Select>

                    <small className="form-text text-muted">Si deseas que el vendedor pueda iniciar sesión, debes asignar un usuario</small>
        
                    {errors.user ? <div className='text-danger'>{errors.user?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['office_phone'].exclusiveTests.required && <span className="text-danger">*</span>} Teléfono de oficina</label>
                    <input type="tel" className='form-control' {...register('office_phone')} />
                    {errors.office_phone ? <div className='text-danger'>{errors.office_phone?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['personal_phone'].exclusiveTests.required && <span className="text-danger">*</span>} Teléfono personal</label>
                    <input type="tel" className='form-control' {...register('personal_phone')} />
                    {errors.personal_phone ? <div className='text-danger'>{errors.personal_phone?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/sellers')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default SellerForm