import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es-mx';

import Layout from '../layout/Layout';
import QuoteService from '../../services/quote.service';
import { moneyFormat } from '../../utils/currencies';

const QuoteDetail = () => {
    const [quote, setQuote] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const loadQuote = async () => {
        QuoteService.getQuote(id)
        .then(response => {
            for(let i = 0; i < response.data.quote_items.length; i++){
                response.data.quote_items[i].unit_price = moneyFormat(response.data.quote_items[i].unit_price, 'MXN')[0]
            }
            setQuote(response.data)
        })
    }

    moment.locale('es-mx')

    useEffect(() => {
        loadQuote();
    }, [])

    return <Layout>

        <div className='row justify-content-center'>
            <div className='col-lg-3'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Detalles de la cotización</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Número de cotización:</span>
                                {quote.id}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Cliente:</span>
                                {quote.lead?.contact?.name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Empresa:</span>
                                {quote.lead?.contact?.customer?.company}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Vigencia:</span>
                                {moment(quote.validity).format('YYYY-MM-DD')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Condiciones de pago:</span>
                                {quote.payment_conditions}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Vendedor:</span>
                                {quote.lead?.seller?.identifier} - {quote.lead?.seller?.user?.first_name} {quote.lead?.seller?.user?.last_name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Creado por:</span>
                                {quote.created_by} el {moment(quote.created_at).format('LLLL')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Última modificación por:</span>
                                {quote.last_updated_by} el {moment(quote.last_updated_at).format('LLLL')}
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div className='col-lg-9'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Artículos</h4>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <div className='table-responsive table-grid'>
                            <table className='table-centered table table-hover'>
                                <thead>
                                    <tr>
                                        <th>Imagen</th>
                                        <th>Alias</th>
                                        <th>Descripción</th>
                                        <th>Cantidad</th>
                                        <th>Precio unitario</th>
                                        <th>Días de entrega</th>
                                        <th>Notas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        quote.quote_items?.map((obj, i) => {
                                            return <tr key={i}>
                                                <td><img src={obj.item?.image ? `${obj.item?.image}?token=${localStorage.getItem('access')}` : obj.item?.image_url} alt={obj.item.alias} width={70} height={70}/></td>
                                                <td>{obj.item?.alias}</td>
                                                <td>{obj.item?.description}</td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.unit_price}</td>
                                                <td>{obj.delivery_time}</td>
                                                <td>{obj.notes}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className='col-12'>
                <div className='d-flex justify-content-between mt-4 mb-2'>
                    <button className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/quotes')}>Regresar</button>
                </div>
            </div>

        </div>

    </Layout>
}

export default QuoteDetail;