import api from './api'

class CustomerService {
    getCustomers(params={}){
        return api.get('/api/customers/', {params});
    }

    getCustomer(id){
        return api.get('/api/customers/' + id + '/')
    }

    createCustomer(payload={}){
        return api.post('/api/customers/', payload)
    }

    updateCustomer(id, payload={}){
        return api.put('/api/customers/' + id + '/', payload)
    }

    deleteCustomer(id){
        return api.delete('/api/customers/' + id + '/')
    }

    getBillingProfile(id){
        return api.get('/api/customers/billing-profiles/' + id + '/')
    }

    createBillingProfile(payload={}){
        return api.post('/api/customers/billing-profiles/', payload)
    }

    updateBillingProfile(id, payload={}){
        return api.put('/api/customers/billing-profiles/' + id + '/', payload)
    }

    deleteBillingProfile(id){
        return api.delete('/api/customers/billing-profiles/' + id + '/')
    }

    getContact(id){
        return api.get('/api/customers/contacts/' + id + '/')
    }

    createContact(payload={}){
        return api.post('/api/customers/contacts/', payload)
    }

    updateContact(id, payload={}){
        return api.put('/api/customers/contacts/' + id + '/', payload)
    }

    deleteContact(id){
        return api.delete('/api/customers/contacts/' + id + '/')
    }

    getRelatedCustomers(){
        return api.get('/api/customers/related-customers/')
    }
}

export default new CustomerService()