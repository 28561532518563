import { Dropdown } from 'react-bootstrap';

const Actions = ({actions}) => {
    return <Dropdown>
                <Dropdown.Toggle variant="link">
                    <i className='dripicons-gear'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-animated">
                    {
                        actions.map((action, i) => {
                            if (action.hasPerm) return <Dropdown.Item key={i} href={action.link}>{action.label}</Dropdown.Item>
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>;
}

export default Actions;