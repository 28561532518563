import { useSelector } from 'react-redux';
import { useRef, useState, useEffect, useMemo } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/es-mx';
import fileDownload from 'js-file-download';

import { hasPermissions } from '../../utils/permissions';
import { checkSuccessMessage } from '../../utils/messages';
import Pagination from '../layout/components/Pagination';
import Actions from '../layout/components/Actions';
import Layout from '../layout/Layout'
import CardMenu from '../layout/components/CardMenu'
import Modal from '../layout/components/Modal';
import QuoteService from '../../services/quote.service';
import UtilsService from '../../services/utils.service';
import CustomerService from '../../services/customer.service';


const QuoteList = () => {
    const permissions = useSelector(state => state.Auth.permissions)
    const modalRef = useRef()
    const location = useLocation()
    const currentPath = location.pathname + location.search;
    const [loadingData, setLoadingData] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [pagination, setPagination] = useState({previous: null, next: null})
    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (newValue) => {
        setSearchTerm(newValue);
    };

    const filters = {
        q: searchParams.get('q'),
        customer: searchParams.getAll('customer').filter(element => {return element !== ''}),
        seller: searchParams.getAll('seller').filter(element => {return element !== ''}),
        lead: searchParams.getAll('lead').filter(element => {return element !== ''}),
        initial_date: searchParams.get('initial_date'),
        final_date: searchParams.get('final_date'),
        page: searchParams.get('page') || 1
    }

    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [customers, setCustomers] = useState([])
    const loadCustomers = async () => {
        CustomerService.getRelatedCustomers()
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label};
            });
            setCustomers(res);

            const urlCustomers = res.map((obj)=>{
                if(filters['customer'].includes(obj.value.toString())) return obj
            });
            setSelectedCustomers(urlCustomers);
        })
    }
    const customerOptions = useMemo(() => {
        const filtered = customers.filter((customer) =>
            customer.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [customers, searchTerm]);

    const [selectedSellers, setSelectedSellers] = useState([])
    const [sellers, setSellers] = useState([])
    const loadSellers = async () => {
        UtilsService.getRelatedField('sellers', 'Seller')
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label};
            });
            setSellers(res);

            const urlSellers = res.map((obj)=>{
                if(filters['seller'].includes(obj.value.toString())) return obj
            });
            setSelectedSellers(urlSellers);
        })
    }
    const sellerOptions = useMemo(() => {
        const filtered = sellers.filter((seller) =>
            seller.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [sellers, searchTerm]);

    const [quotes, setQuotes] = useState([])
    const loadQuotes = async () => {
        setLoadingData(true);
        QuoteService.getQuotes(filters)
        .then((response) => {
            setQuotes(response.data.results);
            setPagination({
                previous: response.data.previous,
                next: response.data.next 
            })
            setLoadingData(false);
        })
    }

    const [loadingPDF, setLoadingPDF] = useState({})
    const downloadPDF = (id) => {
        setLoadingPDF((prev) => {return {...prev, [id]: true}})
        QuoteService.downloadPDF(id)
        .then(response => {
            fileDownload(response.data, 'cotizacion.pdf')
            setLoadingPDF((prev) => {return {...prev, [id]: false}})
        })
    }

    moment.locale('es-mx')

    useEffect(() => {
        loadQuotes();
        loadCustomers();
        loadSellers();
        checkSuccessMessage();
    }, []);

    return <Layout title='Cotizaciones'>
        <CardMenu>
            <div className="mb-3 text-end">
                <button className='btn btn-light me-1' onClick={() => {modalRef.current.toggleModal()}}>Filtros</button>
                {hasPermissions(['quotes.cus_add_quote'], permissions) && 
                    <Link 
                    to={'/quotes/add' + '?prev_url=' + currentPath}
                    className='btn btn-primary'>
                        Nueva cotización
                    </Link>
                }
            </div>
            <div className='table-responsive table-grid'>
                <table className='table-centered table table-hover'>
                    <thead>
                        <tr>
                            <th># Cotización</th>
                            <th>Cliente</th>
                            <th>Empresa</th>
                            <th>Fecha creación</th>
                            {
                                hasPermissions(['quotes.cus_view_quotes_from_other_sellers'], permissions) &&
                                <th>Vendedor</th>
                            }
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loadingData ?
                                quotes.length ? 
                                    quotes.map((obj, i) => {
                                        return <tr key={i}>
                                            <td>{obj.id}</td>
                                            <td>{obj.lead?.contact?.name}</td>
                                            <td>{obj.lead?.contact?.customer?.company}</td>
                                            <td>{moment(obj.created_at).format('LLLL')}</td>
                                            {
                                                hasPermissions(['quotes.cus_view_quotes_from_other_sellers'], permissions) &&
                                                <td>{obj.lead?.seller?.identifier} - {obj.lead?.seller?.user?.first_name} {obj.lead?.seller?.user?.last_name}</td>
                                            }
                                            <td>
                                                <div className='d-flex'>
                                                    <Actions
                                                    actions={[
                                                        {'label': 'Detalles', 'link': 'quotes/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['quotes.cus_view_quote'], permissions)},
                                                        {'label': 'Editar', 'link': 'quotes/edit/' + obj.id  + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['quotes.cus_change_quote'], permissions)},
                                                        {'label': 'Eliminar', 'link': 'quotes/delete/' + obj.id  + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['quotes.cus_delete_quote'], permissions)}
                                                    ]}></Actions>
                                                    {
                                                        hasPermissions(['quotes.cus_view_quote'], permissions) &&
                                                        <button type="button" className="btn btn-link" disabled={loadingPDF[obj.id]} onClick={() => downloadPDF(obj.id)}>
                                                            <i class="dripicons-download me-1"></i>
                                                            {
                                                                !loadingPDF[obj.id] ? 
                                                                <span>PDF</span>
                                                                :
                                                                <span>Descargando...</span>
                                                            }
                                                        </button>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                })
                                :<tr><td colSpan={6}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                            :<tr><td colSpan={6}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                        }
                    </tbody>
                </table>
                <Pagination previous={pagination['previous']} next={pagination['next']}></Pagination>
            </div>
        </CardMenu>

        <Modal title='Filtros' ref={modalRef}>
            <form method='GET'>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Palabra clave</label>
                    <input type="text" className='form-control w-100' name='q' defaultValue={filters['q']} />
                </div>
                <div className='mb-3'>
                    <label htmlFor="customer" className="me-2">Cliente</label>
                    <Select
                        name='customer'
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        isMulti
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={customerOptions}
                        filterOption={() => true}
                        onInputChange={handleInputChange}
                        onChange={(option, action) => {setSelectedCustomers(option)}}
                        value={selectedCustomers}>
                    </Select>
                </div>
                {
                    hasPermissions(['quotes.cus_view_quotes_from_other_sellers'], permissions) &&
                    <div className='mb-3'>
                        <label htmlFor="seller" className="me-2">Vendedor</label>
                        <Select
                            name='seller'
                            className="react-select"
                            classNamePrefix="react-select"
                            isClearable
                            isMulti
                            placeholder="Selecciona una opción"
                            noOptionsMessage={() => 'No se encontraron resultados'}
                            options={sellerOptions}
                            filterOption={() => true}
                            onInputChange={handleInputChange}
                            onChange={(option, action) => {setSelectedSellers(option)}}
                            value={selectedSellers}>
                        </Select>
                    </div>
                }
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Fecha inicio</label>
                    <input type="date" className='form-control w-100' name='initial_date' defaultValue={filters['initial_date']} />
                </div>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Fecha fin</label>
                    <input type="date" className='form-control w-100' name='final_date' defaultValue={filters['final_date']} />
                </div>
                <div className="mb-3 text-end">
                    <input type="submit" className='btn btn-primary' value='Buscar' />
                </div>
            </form>
        </Modal>

    </Layout>
}

export default QuoteList